.header {
    display: flex;
    flex-flow: row;
    
    justify-content: space-between;
    align-items: center;
    border: 1px solid green;
}

.button {
    border: 1px solid black;
}

.root {
    flex-grow: 1;
}

.grow {
    flex-grow: 1;
}

.menuButton {
    margin-left: -12;
    margin-right: 20;
}